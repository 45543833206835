'use client';

import { useEffect, type FunctionComponent } from 'react';
import UserInteractionService, {
  EventType
} from '@/services/isomorphic/UserInteractionService';
import type { IPage } from '@/services/models/Page';
import { useDebounce } from '@/react/hooks/useDebounce';
import { evaluateBreakpoints } from '@/react/hooks/useBreakpoints';

/** The scroll event interface. */
interface IScrollEvent {
  /** The percentage of the page that has been scrolled. */
  percentage: number;
}

interface IUserInteractionScrollProps {
  /** The page we are tracking the scroll event on. */
  page: IPage;
  /**
   * The scroll events that we are tracking.
   * For each percentage in the array, we will trigger a scroll event to Dynamic
   * Yield (DY) when the user scrolls to that percentage of the page.
   */
  scrollEvents: Array<IScrollEvent>;
}

/**
 * UserInteractionScroll is a component that listens to the scroll position and triggers DY events.
 */
export const UserInteractionScroll: FunctionComponent<
  IUserInteractionScrollProps
> = ({ page, scrollEvents }) => {
  const debounce = useDebounce();

  useEffect(
    function setupScrollEventListener() {
      const triggeredEvents: Record<number, boolean> = {};

      const onScroll = (): void => {
        const { isPhone } = evaluateBreakpoints(window.innerWidth);
        const device = isPhone ? 'mobile' : 'desktop';

        const scrollTop = window.scrollY;
        const windowHeight = window.innerHeight;
        const docHeight = document.documentElement.scrollHeight;

        const scrollPosition = scrollTop + windowHeight;
        /**
         * For each scroll event, we check if the user has scrolled to the percentage of
         * the page that we are tracking. If they have, we trigger a scroll event to DY.
         */
        scrollEvents.forEach((scrollEvent) => {
          const { percentage } = scrollEvent;

          const triggerPosition = docHeight * (percentage / 100);

          if (
            scrollPosition >= triggerPosition &&
            !triggeredEvents[percentage]
          ) {
            UserInteractionService.makeAction({
              action: EventType.Scroll,
              scrollPositionPercentage: percentage,
              page,
              device
            });

            /*
             * Mark the scroll event position as triggered. This is to prevent the
             * same event from being triggered multiple times.
             */
            triggeredEvents[percentage] = true;
          }
        });
      };

      /**
       * We debounce the onScroll function to prevent it from being called too
       * frequently as the scroll event on the browser can fire at a very high rate.
       */
      const debouncedOnScroll = (): void => {
        debounce(100, onScroll);
      };

      const controller = new AbortController();
      const { signal } = controller;

      window.addEventListener('scroll', debouncedOnScroll, { signal });

      return () => {
        controller.abort();
      };
    },
    [page, scrollEvents, debounce]
  );

  return null;
};
